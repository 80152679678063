<script>
import simplebar from "simplebar-vue";
import MetisMenu from "metismenujs/dist/metismenujs";

import { layoutMethods } from "@/state/helpers";

/**
 * Sidebar component
 */
export default {
  components: {
    simplebar
  },
  mounted: function() {
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;
      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");
            const parent4 = parent3.parentElement;
            if (parent4) parent4.classList.add("mm-show");
            const parent5 = parent4.parentElement;
            if (parent5) parent5.classList.add("active");
          }
        } else {
          parent.classList.add("mm-active");
        }
      }
    }
  },
  methods: {
    ...layoutMethods,
    changeLayout(layout) {
      this.changeLayoutType({ layoutType: layout });
    },
    compactSidebar() {
      document.body.setAttribute("data-sidebar-size", "small");
      document.body.classList.remove("vertical-collpsed");
      document.body.removeAttribute("data-keep-enlarged", "true");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    iconSidebar() {
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-keep-enlarged", "true");
      document.body.removeAttribute("data-sidebar-size", "small");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    boxedLayout() {
      document.body.setAttribute("data-layout-size", "boxed");
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-keep-enlarged", "true");
    },
    checkMasterDataPermission() {
      const masterDataPermission = ['VIEW_CATEGORY','VIEW_TREATMENT','VIEW_LOCATION','VIEW_ROOM','VIEW_EMPLOYEE','VIEW_PACKAGE','VIEW_EQUIPMENT']
      return this.$store.getters.myPermission.some(item => masterDataPermission.includes(item));
    },
    checkSettingPermission() {
      const settingPermission = ['VIEW_ROLE','VIEW_STAFF']
      return this.$store.getters.myPermission.some(item => settingPermission.includes(item));
    }
  }
};
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->
  <div class="vertical-menu">
    <simplebar class="h-100">
      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <!-- Left Menu Start -->
        <ul class="metismenu list-unstyled" id="side-menu">
          <li class="menu-title">Main Menu</li>

          <li>
            <router-link tag="a" to="/home" class="side-nav-link">
              <i class="ti-home"></i>
              <!-- <span class="badge badge-pill badge-primary float-right">2</span> -->
              <span>Dashboard</span>
            </router-link>
          </li>

          <li v-if="$store.getters.checkPermission('CALENDAR')">
            <router-link tag="a" to="/calendar" class="side-nav-link">
              <i class="ti-calendar"></i>
              <span>Calendar</span>
            </router-link>
          </li>

          <li v-if="$store.getters.checkPermission('VIEW_APPOINTMENT')">
            <router-link tag="a" to="/appointment" class="side-nav-link">
              <i class="ti-pin-alt"></i>
              <span>Appointment</span>
            </router-link>
          </li>

          <li v-if="$store.getters.checkPermission('VIEW_CHECKOUT_PRODUCT')">
            <router-link tag="a" to="/buy-product" class="side-nav-link">
              <i class="ti-shopping-cart-full"></i>
              <span>Sale Product</span>
            </router-link>
          </li>

          <li v-if="$store.getters.checkPermission('VIEW_USER')">
            <router-link tag="a" to="/user" class="side-nav-link">
              <i class="ti-user"></i>
              <span>User</span>
            </router-link>
          </li>

          <li class="menu-title" v-if="checkMasterDataPermission()">Master Data</li>

          <li v-if="$store.getters.checkPermission('VIEW_CATEGORY')">
            <router-link tag="a" to="/category" class="side-nav-link">
              <i class="ti-view-list-alt"></i>
              <span>Category</span>
            </router-link>
          </li>

          <li v-if="$store.getters.checkPermission('VIEW_TREATMENT')">
            <router-link tag="a" to="/treatment" class="side-nav-link">
              <i class="ti-support"></i>
              <span>Treatment</span>
            </router-link>
          </li>

          <li v-if="$store.getters.checkPermission('VIEW_LOCATION')">
            <router-link tag="a" to="/location" class="side-nav-link">
              <i class="ti-map"></i>
              <span>Location</span>
            </router-link>
          </li>

          <li v-if="$store.getters.checkPermission('VIEW_ROOM')">
            <router-link tag="a" to="/room" class="side-nav-link">
              <i class="ti-layout-tab-v"></i>
              <span>Room</span>
            </router-link>
          </li>

          <li v-if="$store.getters.checkPermission('VIEW_EMPLOYEE')">
            <router-link tag="a" to="/employee" class="side-nav-link">
              <i class="ti-id-badge"></i>
              <span>Employee</span>
            </router-link>
          </li>

          <!-- <li>
            <router-link tag="a" to="/series" class="side-nav-link">
              <i class="ti-vector"></i>
              <span>Series</span>
            </router-link>
          </li> -->
          
          <li v-if="$store.getters.checkPermission('VIEW_PACKAGE')">
            <router-link tag="a" to="/package" class="side-nav-link">
              <i class="ti-package"></i>
              <span>Package</span>
            </router-link>
          </li>

          <li v-if="$store.getters.checkPermission('VIEW_VOUCHER')">
            <router-link tag="a" to="/voucher" class="side-nav-link">
              <i class="ti-ticket"></i>
              <span>Voucher</span>
            </router-link>
          </li>
          
          <li v-if="$store.getters.checkPermission('VIEW_EQUIPMENT')">
            <router-link tag="a" to="/equipment" class="side-nav-link">
              <i class="ti-briefcase"></i>
              <span>Equipment</span>
            </router-link>
          </li> 
          
          <li class="menu-title" v-if="checkSettingPermission()">Settings</li>

          <li v-if="$store.getters.checkPermission('VIEW_ROLE')">
            <router-link tag="a" to="/role" class="side-nav-link">
              <i class="ti-panel"></i>
              <span>Role</span>
            </router-link>
          </li> 

          <li v-if="$store.getters.checkPermission('VIEW_STAFF')">
            <router-link tag="a" to="/staff" class="side-nav-link">
              <i class="ti-widget"></i>
              <span>Staff</span>
            </router-link>
          </li> 

        </ul>
      </div>
      <!-- Sidebar -->
    </simplebar>
  </div>
  <!-- Left Sidebar End -->
</template>